<template>
  <div class="password">
    <div class="password-title">パスワード再設定</div>
    <div class="password-subtitle">
      「<a href = "/terms.html" target="_blank">利用規約</a>」
      「<a href = "https://www.tkhs.co.jp/about/privacy.html" target="_blank">個人情報の取り扱いについて</a>」
      <span>に同意の上ご利用ください。</span>
    </div>
    <el-form ref="resetPasswordForm" :model="form" class="app-form" :rules="rules">
      <el-form-item label="新しいパスワード" prop="newPassword">
        <el-input type="password" v-model="form.newPassword" autocomplete="off"
          placeholder="新しいパスワード"
        ></el-input>
      </el-form-item>
      <el-form-item label="新しいパスワード（再入力）" prop="newPasswordConfirm">
        <el-input type="password" v-model="form.newPasswordConfirm" autocomplete="off"
          placeholder="新しいパスワード（再入力）"
        ></el-input>
        <div style="color: #888">※半角英数字6字以上で、数字とアルファベットをそれぞれ1字以上含めてください。</div>
      </el-form-item>
      <el-form-item>
        <el-button class="studypack-button-primary" type="primary" @click="onSubmit">送信</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import request from '@/utils/request';
import Cookies from 'js-cookie';
import { Notification } from 'element-ui';
import tools from '@/utils/tools';

const { checkPassword } = tools;

export default {
  data() {
    return {
      form: {
        newPassword: '',
        newPasswordConfirm: '',
      },
      rules: {
        newPassword: [
          { required: true, message: '必須です。', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' },
        ],
        newPasswordConfirm: [
          { validator: this.newPasswordConfirm, trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    newPasswordConfirm(rule, value, callback) {
      if (value === '') {
        callback(new Error('パスワードを入力ください。'));
      } else if (value !== this.form.newPassword) {
        callback(new Error('パスワードは不一致です。やり直してパスワードを再度入力ください。'));
      } else {
        callback();
      }
    },
    onSubmit() {
      //const { reset_password_token: resetPasswordToken } = this.$route.params;
      const { reset_password_token: resetPasswordToken } = this.$route.query;
      console.log("###>トークン無効対応");
      console.log(this.$route.query);
      console.log(resetPasswordToken);

      this.$refs.resetPasswordForm.validate((valid) => {
        if (valid) {
          Cookies.remove('token');
          request('firstResetPassword', {
            password: this.form.newPassword,
            token :resetPasswordToken
          }).then(() => {
            Notification({
              title: '成功',
              message: 'パスワードお問い合わせが成功しました。お問い合わせのメールにてパスワード再設定ください。',
              // duration: 0,
              offset: 75,
              type: 'success',
            });
            this.$router.push({
              path: '/login',
            }).catch(() => {});
          });
          return true;
        }
        console.log('error submit!!');
        return false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.password {
  margin: 40px auto;
  max-width: 600px;

  .password-title {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .password-subtitle {
    text-align: center;
    font-size: 18px;
    margin-bottom: 24px;
  }
  .password-docs {
    font-size: 16px;
    margin-bottom: 24px;
    line-height: 1.2;
  }
}
</style>
